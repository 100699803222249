export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Award Name',
        key: 'Name',
        minWidth: 80,
    },

    {
        title: 'Description',
        key: 'Description',
        minWidth: 80,
    }
]
